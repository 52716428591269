/* Flexnav Base Styles */
#navigation .flexnav {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    transition: none;
    -webkit-transform-style: preserve-3d;
    overflow: hidden;
    margin: 0 auto;
    max-height: 0;
    position: relative;
    padding-left: 0;
    z-index: 10;
}

#navigation .flexnav.opacity {
    opacity: 0;
}

#navigation .flexnav.flexnav-show {
    max-height: 2000px;
    opacity: 1;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

#navigation .flexnav.one-page {
    position: fixed;
    top: 50px;
    right: 5%;
    max-width: 200px;
}

#navigation .flexnav li {
    font-size: 100%;
    position: relative;
    list-style-type: none;
    overflow: hidden;
}

#navigation .flexnav li a {
    position: relative;
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    z-index: 2;
    color: #222222;
    padding: 5px 10px;
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    width: 100%;
}

#navigation .flexnav li a.selected {
    background-color: #FD2996;
    color: #FFFFFF;
}

#navigation .flexnav li ul {
    width: 100%;
    padding: 0;
}

#navigation .flexnav li ul li {
    font-size: 100%;
    position: relative;
    overflow: hidden;
    background: #ffffff none repeat scroll 0 0;
    padding: 0 15px;
}

#navigation .flexnav li ul.flexnav-show li {
    overflow: visible;
}

#navigation .flexnav li ul li a {
    display: block;
}

#navigation .flexnav ul li ul li a {
    background: #bfbfbc;
}

#navigation .flexnav ul li ul li ul li a {
    background: #cbcbc9;
}

#navigation .flexnav .touch-button {
    background: rgba(0, 0, 0, 0.075) none repeat scroll 0 0;
    display: block;
    float: right;
    height: 33px;
    text-align: center;
    vertical-align: top;
    width: 50px;
    z-index: 999;
    top: 0;
    right: 0;
    position: absolute;
}

#navigation .flexnav .touch-button:hover {
    cursor: pointer;
}

#navigation .flexnav .touch-button .navicon {
    position: relative;
    top: 20%;
    font-size: 12px;
    color: #666;
}

#navigation .menu-button {
    position: relative;
    display: block;
    cursor: pointer;
    float: right;
    margin-right: 9px;
    margin-top: 9px;
}

#navigation .menu-button.one-page {
    position: fixed;
    top: 0;
    right: 5%;
    padding-right: 45px;
}

#navigation .menu-button .touch-button {
    display: none;
    background: transparent;
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    text-align: center;
}

#navigation .menu-button .touch-button .navicon {
    font-size: 16px;
    position: relative;
    top: 1em;
    color: #666;
}

@media all and (min-width: 768px) {
    body.one-page {
        padding-top: 70px;
    }

    #navigation .flexnav {
        overflow: visible;
        max-height: none;
    }

    #navigation .flexnav.opacity {
        opacity: 1;
    }

    #navigation .flexnav.one-page {
        top: 0;
        right: auto;
        max-width: 1080px;
    }

    #navigation .flexnav li {
        position: relative;
        list-style: none;
        display: inline-block;
        overflow: visible;
    }

    #navigation .flexnav li > a:focus, #navigation .flexnav li > a:hover {
        background: #F5F5F5;
        color: #262626;
        text-decoration: none;
    }

    #navigation .flexnav li a {
        border-bottom: none;
    }

    #navigation .flexnav li > ul {
        background-clip: padding-box;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
        float: left;
        font-size: 14px;
        left: 0;
        list-style: outside none none;
        margin: 0;
        min-width: 160px;
        padding: 5px 0;
        position: absolute;
        text-align: left;
        top: 100%;
        z-index: 1000;
        display: none;
    }

    #navigation .flexnav li > ul li {
        width: 100%;
    }

    #navigation .flexnav li ul li > ul {
        margin-left: 100%;
        top: 0;
    }

    #navigation .flexnav li ul li a {
        border-bottom: none;
        padding: 10px;
    }

    #navigation .flexnav li ul.open {
        display: block;
        opacity: 1;
        visibility: visible;
        z-index: 1;
    }

    #navigation .flexnav li ul.open li {
        overflow: visible;
        max-height: 100px;
    }

    #navigation .flexnav li ul.open ul.open {
        margin-left: 100%;
        top: 0;
    }

    #navigation .flexnav li ul li {
        padding: 0;
    }

    .menu-button,
    #navigation .flexnav .touch-button {
        display: none;
    }
}

.oldie body.one-page {
    padding-top: 70px;
}

.oldie .flexnav {
    overflow: visible;
}

.oldie .flexnav.one-page {
    top: 0;
    right: auto;
    max-width: 1080px;
}

.oldie .flexnav li {
    position: relative;
    list-style: none;
    float: left;
    display: block;
    background-color: #a6a6a2;
    width: 20%;
    min-height: 50px;
    overflow: visible;
}

.oldie .flexnav li:hover > ul {
    display: block;
    width: 100%;
    overflow: visible;
}

.oldie .flexnav li:hover > ul li {
    width: 100%;
    float: none;
}

.oldie .flexnav li a {
    border-left: 1px solid #acaca1;
    border-bottom: none;
    overflow: visible;
}

.oldie .flexnav li > ul {
    background: #acaca1;
    position: absolute;
    top: auto;
    left: 0;
    display: none;
    z-index: 1;
    overflow: visible;
}

.oldie .flexnav li ul li ul {
    top: 0;
}

.oldie .flexnav li ul li a {
    border-bottom: none;
}

.oldie .flexnav li ul.open {
    display: block;
    width: 100%;
    overflow: visible;
}

.oldie .flexnav li ul.open li {
    width: 100%;
}

.oldie .flexnav li ul.open ul.open {
    margin-left: 100%;
    top: 0;
    display: block;
    width: 100%;
    overflow: visible;
}

.oldie .flexnav ul li:hover ul {
    margin-left: 100%;
    top: 0;
}

.oldie .menu-button {
    display: none;
}

.oldie.ie7 .flexnav li {
    width: 19.9%;
}
